.header {
    margin-bottom: 5px;
    /* border: 1px solid #ddd; */
    /* border-radius: 8px; */
    background-color: #fdfdfd;
    padding: 10px; /* Optional: Add padding for better spacing */
  }
  
  nav ul {
    list-style-type: none; /* Remove default list styling */
    padding: 0; /* Remove default padding */
  }
  
  nav ul li {
    display: inline; /* Display items inline */
    margin-right: 15px; /* Space between items */
  }
  
  nav ul li a {
    text-decoration: none; /* Remove underline from links */
    color: #007bff; /* Link color */
  }
  
  nav ul li a:hover {
    text-decoration: underline; /* Underline on hover */
  }
  
  .header h1 {
        padding-left: 50px;
    }