/* ThomasHeader.css */
.app-container {
    display: flex;
    justify-content: space-between; /* Space between text and image */
    align-items: center;            /* Center vertically */
    padding: 20px 40px;            /* Add padding to the top/bottom and left/right */
    max-width: 1000px;             /* Limit max width for larger screens */
    margin: 0 auto;                /* Center the container horizontally */
}

.text-section {
    max-width: 50%; /* Limit the width of the text section */
}

.text-section h1 {
    font-size: 2.5rem; /* Larger font size for the main heading */
    color: #333;       /* Darker color for better readability */
    margin: 0 0 10px; /* Remove top margin, add space below */
}

.text-section h2, 
.text-section h3, 
.text-section h4 {
    margin: 5px 0; /* Add some margin for better spacing */
    color: #555;    /* Slightly lighter color for subheadings */
}

.text-section h2 {
    font-size: 1.8rem; /* Slightly smaller font size for subheadings */
    font-weight: 600;  /* Semi-bold */
}

.text-section h3 {
    font-size: 1.5rem; /* Medium font size for third heading */
    font-weight: 500;  /* Medium weight */
}

.text-section h4 {
    font-size: 1.2rem; /* Smaller font size for fourth heading */
    font-weight: 400;  /* Regular weight */
}

.text-section a {
    display: inline-block; /* Make links block elements for better spacing */
    margin-top: 10px;     /* Add space above the links */
    color: #007bff;       /* Link color */
    text-decoration: none; /* Remove underline from links */
    font-weight: 500;     /* Medium weight */
}

.text-section a:hover {
    text-decoration: underline; /* Underline on hover */
}

.image-section {
    max-width: 40%; /* Limit the width of the image section */
}

.cereal-image {
    width: 350px; /* Set a fixed width */
    height: 350px; /* Set a fixed height */
    border-radius: 50%; /* Make the image circular */
    object-fit: cover; /* Crop the image to fit */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .app-container {
        flex-direction: column; /* Stack items vertically */
        align-items: center;     /* Center items */
        padding: 20px;          /* Reduce padding on small screens */
    }
    .text-section {
        text-align: center; /* Center text on small screens */
        max-width: 100%;    /* Allow full width for text section */
    }
    .image-section {
        max-width: 100%;     /* Allow full width for image section */
        margin-top: 20px;    /* Add some space above the image */
    }
}

.text-section a {
    color: #333; /* Change to your desired color, e.g., dark gray */
    text-decoration: none; /* Remove underline from links */
}
