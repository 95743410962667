/* Copyright.css */
.footer {
    text-align: center; /* Center the text */
    padding: 10px 0;   /* Add some padding */
    background-color: #f1f1f1; /* Light gray background */
    color: #333;       /* Darker text color */
    position: absolute; /* Allow positioning */
    bottom: 0;         /* Stick to the bottom */
    width: 100%;       /* Full width */
}
